mat-checkbox.mat-checkbox-checked.mat-accent span.mat-checkbox-background {
    background-color: #20623B;
}

mat-checkbox.mat-checkbox-checked.mat-accent.red-checkbox span.mat-checkbox-background {
    background-color: #c31111;
}

mat-checkbox.mat-checkbox-disabled {
    cursor: not-allowed;
}

svg.mat-checkbox-checkmark {
    display: none;
}