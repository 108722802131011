.container {
    padding: 37px 26px;
    min-height: 620px;
}

.modal-container {
    width: 700px;
}

.spinner-container {
    height: 670px;
}