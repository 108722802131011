@import 'src/style/color';

.my-row {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.my-row.following-row {
    margin-top: 50px;
}

.my-row-element-3 {
    width: 30%;
}

.my-row-element-2 {
    width: 45%;
}

.my-row-element-1 {
    width: 80%;
}

.my-input {
    border: none;
    border-bottom: 1.3px solid $credem-green;
    width: 100%;
    font-size: 18px;
    margin-top: 5px;
}


.my-input[disabled]{
    border-bottom: none;
    background-color: inherit;
}

.my-input:focus {
    outline: none;
}

.legend {
    color: $credem-green;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}

.my-select {
    text-align: center;
    border: 2px solid $credem-green;
    border-radius: 20px;
    font-size: 16px;
    height: 30px;
    width: 60px;
    outline: none;
}