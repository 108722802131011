@import 'src/style/color';

.breadcrumb {
    color: $credem-green;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 48px;
    margin-top: 48px;
}

.breadcrumb-previous {
    cursor: pointer;
}

.breadcrumb-current {
    text-decoration: underline;
}