.spinner-loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.spinner-double-dot-stick {
    display: block;
    float: left;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 4.125px solid rgba(50, 41, 41, 0.20);
    animation: spinner 1.2s linear infinite;
}
    
@keyframes spinner {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
  
.spinner-double-dot-stick {
    position: relative;
}
  
.spinner-double-dot-stick:before,
.spinner-double-dot-stick:after {
    content: "";
    position: absolute;
    top: 6.25px;
    left: 35.875px;
    width: 12.25px;
    height: 12.25px;
    border-radius: 50%;
    background: #20623b;
}
    
.spinner-double-dot-stick:after {
    top: auto;
    bottom: 6.25px;
}
    
.spinner-double-dot-stick:before,
.spinner-double-dot-stick:after {
    top: -6.25px;
}
  
.spinner-double-dot-stick:after {
    top: auto;
    bottom: -6.25px;
}