@import 'src/style/color';

mat-form-field.my-form-field-color {
    color: $credem-green;
}

mat-form-field.mat-focused .mat-form-field-underline .mat-form-field-ripple {
    background-color: $credem-green;
}

mat-form-field.mat-focused .mat-form-field-infix .mat-form-field-label {
    color: $credem-green;
}