@import 'src/style/color';

mat-radio-button.mat-radio-button.mat-radio-checked.mat-accent span.mat-radio-outer-circle {
    border-color: $credem-green;
}

mat-radio-button.mat-radio-button.mat-radio-checked.mat-accent span.mat-radio-inner-circle {
    background-color: $credem-green;
}

mat-radio-button.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    display: none;
}