.spinner-loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 90%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-double-dot-stick {
  display: block;
  float: left;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4.125px solid rgba(50, 41, 41, 0.2);
  animation: spinner 1.2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-double-dot-stick {
  position: relative;
}

.spinner-double-dot-stick:before,
.spinner-double-dot-stick:after {
  content: "";
  position: absolute;
  top: 6.25px;
  left: 35.875px;
  width: 12.25px;
  height: 12.25px;
  border-radius: 50%;
  background: #20623b;
}

.spinner-double-dot-stick:after {
  top: auto;
  bottom: 6.25px;
}

.spinner-double-dot-stick:before,
.spinner-double-dot-stick:after {
  top: -6.25px;
}

.spinner-double-dot-stick:after {
  top: auto;
  bottom: -6.25px;
}

.breadcrumb {
  color: #20623b;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 48px;
  margin-top: 48px;
}

.breadcrumb-previous {
  cursor: pointer;
}

.breadcrumb-current {
  text-decoration: underline;
}

.my-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 13px;
  margin-right: 5%;
}

button.my-button {
  border-radius: 20px;
  width: 20%;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: calc(1vw + 3pt);
  padding: 0;
}

button.my-button.small {
  min-width: 50px;
  width: 50px;
  height: 40px;
}

button.my-button.confirm {
  background-color: #20623b;
  color: #fff;
}

button.my-button.cancel {
  background-color: #c31111;
  color: #fff;
}

button.my-button.back {
  background-color: #fff;
  color: #20623b;
}

mat-checkbox.mat-checkbox-checked.mat-accent span.mat-checkbox-background {
  background-color: #20623B;
}

mat-checkbox.mat-checkbox-checked.mat-accent.red-checkbox span.mat-checkbox-background {
  background-color: #c31111;
}

mat-checkbox.mat-checkbox-disabled {
  cursor: not-allowed;
}

svg.mat-checkbox-checkmark {
  display: none;
}

mat-radio-button.mat-radio-button.mat-radio-checked.mat-accent span.mat-radio-outer-circle {
  border-color: #20623b;
}

mat-radio-button.mat-radio-button.mat-radio-checked.mat-accent span.mat-radio-inner-circle {
  background-color: #20623b;
}

mat-radio-button.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  display: none;
}

mat-datepicker-toggle {
  color: #20623b;
}

mat-form-field.my-form-field-color {
  color: #20623b;
}

mat-form-field.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background-color: #20623b;
}

mat-form-field.mat-focused .mat-form-field-infix .mat-form-field-label {
  color: #20623b;
}

.my-row {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.my-row.following-row {
  margin-top: 50px;
}

.my-row-element-3 {
  width: 30%;
}

.my-row-element-2 {
  width: 45%;
}

.my-row-element-1 {
  width: 80%;
}

.my-input {
  border: none;
  border-bottom: 1.3px solid #20623b;
  width: 100%;
  font-size: 18px;
  margin-top: 5px;
}

.my-input[disabled] {
  border-bottom: none;
  background-color: inherit;
}

.my-input:focus {
  outline: none;
}

.legend {
  color: #20623b;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

.my-select {
  text-align: center;
  border: 2px solid #20623b;
  border-radius: 20px;
  font-size: 16px;
  height: 30px;
  width: 60px;
  outline: none;
}

.container {
  padding: 37px 26px;
  min-height: 620px;
}

.modal-container {
  width: 700px;
}

.spinner-container {
  height: 670px;
}

body {
  margin: 0;
}

.plusSmall,
.minusSmall {
  background-color: #20623b;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  border-radius: 10px;
}

::-webkit-scrollbar:hover {
  background-color: #eaeaea;
  border: none;
}

::-webkit-resizer {
  -webkit-border-radius: 4px;
  background-color: #ccc;
  border: none;
}

::-webkit-scrollbar-thumb {
  min-height: 5px;
  min-width: 5px;
  -webkit-border-radius: 4px;
  background-color: #c2c2c2;
  border: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c2c2c2;
  border: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: #c2c2c2;
  border: none;
}

::-webkit-scrollbar-track {
  margin-top: 15px;
  margin-bottom: 15px;
}