@import 'src/style/color';

.my-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 13px;
    margin-right: 5%;
}

button.my-button {
    border-radius: 20px;
    width: 20%;
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: calc(1vw + 3pt);
    padding: 0;
}

button.my-button.small {
    min-width: 50px;
    width: 50px;
    height: 40px;
}

button.my-button.confirm {
    background-color: $credem-green;
    color: #fff;
}

button.my-button.cancel {
    background-color: $credem-red;
    color: #fff;
}

button.my-button.back {
    background-color: #fff;
    color: $credem-green;
}